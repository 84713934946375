import {
    Form,
    FormProps,
    FormValues,
} from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import {
    QuoteFormKey,
    QuoteFormState,
    quoteFormState,
    QuoteLayout,
    TabsLayout,
    usualDriverFormConfig,
    validateQuoteBusinessRules,
    VehicleHeading,
} from "@modules/quote";
import { CompletedCard, Navigation } from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import {
    filterQuotePages,
    findNextInvalidTab,
    formatDate,
    getNavigationPages,
} from "@modules/shared/helpers";
import { useWtc } from "@modules/shared/hooks";
import { appState, AppState, selectAddressString } from "@modules/shared/state";
import {
    DeepPartial,
    PageInterface,
    PageProps,
    TabsStatus,
} from "@modules/shared/types";

const UsualDriver: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, language, ownPageObjects: allPages } = pageContext;
    const { t } = useTranslation();
    const [formState, setFormState] = useRecoilState(quoteFormState);
    const appData = useRecoilValue<AppState>(appState);
    const { getWtcPage, businessRules } = useWtc(
        allPages,
        PageNames.QuoteWTCContactMe,
    );
    const [formProps, setFormProps] = useState<
        Pick<FormProps<FormValues>, "dirty" | "isValid">
    >({
        isValid: false,
        dirty: false,
    });
    const driver = formState?.drivers?.usualDriver;
    const policyHolder = formState?.drivers?.policyholderInfo;
    const addressString = useRecoilValue(selectAddressString(t)(language));

    useEffect(() => {
        if (!appData?.InsuranceChoice?.insurance?.type) {
            // Navigate to the product page when there is no insurance choice selected
            // A quote flow can only started when there is a simulation available
            navigate("/");
        }
    }, [appData]);

    const pageSubTitle = useMemo(() => {
        if (policyHolder?.headDriverIsPolicyHolder) {
            return `${t("quote.drivers.form.usualDriver")} & ${t(
                "quote.drivers.form.policyholderInfo",
            )}`;
        }
        return t("quote.drivers.form.usualDriver");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialFormValues: DeepPartial<QuoteFormState> = useMemo(
        () => ({
            ...formState,
            usualDriver: {
                ...formState?.usualDriver,
                driverDataFromSimulation: [
                    {
                        title: "all.address",
                        description: addressString,
                    },
                    {
                        title: "all.birthDay",
                        description: formatDate(appData.Driver?.birthday),
                    },
                    {
                        title: "quote.driversLicense",
                        description: formatDate(appData.Driver?.license),
                    },
                ],
                personalDetails: {
                    ...formState?.usualDriver?.personalDetails,
                    firstName:
                        formState?.usualDriver?.personalDetails?.firstName ||
                        driver?.firstName,
                    lastName:
                        formState?.usualDriver?.personalDetails?.lastName ||
                        driver?.lastName,
                },
            },
        }),
        // Only run this when initializing the page
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appData.Driver?.birthday, appData.Driver?.license, appData],
    );

    const handleSubmit = (
        values: FormValues,
        tabsStatus: TabsStatus,
        nextPage?: PageInterface,
    ): void => {
        updateFormState(values);
        const nextInvalidTab = findNextInvalidTab(
            tabsStatus,
            page,
            allPages,
            language,
        );

        const wtcPage = getWtcPage(
            validateQuoteBusinessRules(businessRules, formState, appData)
                .action,
        );

        if (wtcPage) {
            navigate(wtcPage.paths[language]);
            return;
        }
        if (nextInvalidTab) {
            navigate(nextInvalidTab.paths[language]);
            return;
        }
        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const updateFormState = (formValues: QuoteFormState): void => {
        setFormState({
            ...formValues,
            drivers: {
                ...formValues.drivers,
                usualDriver: {
                    ...formValues.drivers?.usualDriver,
                    firstName:
                        formValues.usualDriver?.personalDetails?.firstName,
                    lastName: formValues.usualDriver?.personalDetails?.lastName,
                },
            },
        });
    };

    const handleOnChange = (
        values: FormValues,
        props: Pick<FormProps<FormValues>, "dirty" | "isValid">,
    ): void => {
        setFormProps(props);
        updateFormState(values);
    };

    const renderHeaderComponent = useCallback(
        (tabsStatus: TabsStatus, nextPage?: PageInterface) => {
            const showCompleteCard = formProps.dirty
                ? formProps.isValid
                : tabsStatus && tabsStatus[page.name]?.isValid;
            const nextInvalidTab = findNextInvalidTab(
                tabsStatus,
                page,
                allPages,
                language,
            );

            if (showCompleteCard) {
                return (
                    <CompletedCard
                        nextPage={nextInvalidTab ?? nextPage}
                        currentPageText={`${driver?.firstName} ${driver?.lastName}`}
                        language={language}
                    />
                );
            }
        },
        [driver, language, page, formProps, allPages],
    );

    return (
        <QuoteLayout
            title={t("quote.vehicle.title")}
            page={page}
            allPages={allPages}
            language={language}
        >
            <TabsLayout
                title={t("quote.vehicle.title")}
                description={t("quote.vehicle.description")}
                language={language}
                allPages={allPages}
                activeTabName={page.name}
            >
                {({ tabs, tabsStatus }) => {
                    const filteredTabs = filterQuotePages(allPages, tabs);
                    const { prevPage, nextPage } = getNavigationPages(
                        filteredTabs,
                        page,
                    );
                    const isLastTab =
                        nextPage?.name === PageNames.QuoteInsurances;

                    return (
                        <>
                            <VehicleHeading
                                subTitle={pageSubTitle}
                                title={`${driver?.firstName} ${driver?.lastName}`}
                            />
                            <Form
                                scrollToFieldError
                                errorMessageCardTitle={
                                    t("all.errormessage.title") as string
                                }
                                errorMessageCardSubTitle={
                                    t("all.errormessage.text") as string
                                }
                                formContext={{
                                    t,
                                    nameSpace: QuoteFormKey.UsualDriver,
                                    fieldWrapper: {
                                        optionalLabel: "all.optional",
                                    },
                                }}
                                initialValues={initialFormValues}
                                onSubmit={(values) =>
                                    handleSubmit(values, tabsStatus, nextPage)
                                }
                                onChange={handleOnChange}
                                fields={usualDriverFormConfig.fields}
                                headerComponent={() =>
                                    renderHeaderComponent(tabsStatus, nextPage)
                                }
                                enableReinitialize
                            >
                                <Navigation
                                    t={t}
                                    language={language}
                                    prevPage={prevPage}
                                    nextPage={nextPage}
                                    nextPageBtnText="all.next"
                                    prevPageBtnText="all.previous"
                                    nextColor={isLastTab ? "primary" : "info"}
                                />
                            </Form>
                        </>
                    );
                }}
            </TabsLayout>
        </QuoteLayout>
    );
};

export default UsualDriver;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
